<template>
  <div class="wrap h-100">
    <div class="w-100 code-editor" style="height: 500px" :ref="generateId" v-if="show"></div>
    <!-- <span v-if="withFullscreenBtn" title="全屏显示">
      <svg-icon class="icon-fullscreen" icon-class="fullscreen" :style="{ bottom: (withFooterBtns ? 47 : 10) + 'px' }" @click.native="fullscreen"></svg-icon>
    </span>

    <el-dialog ref="dialog" custom-class="code-dialog" :visible.sync="isVisible" title="脚本编辑" fullscreen append-to-body :show-footer="false" @close="closeEditCode">
      <code-editor v-model="dialogValue"></code-editor>
    </el-dialog> -->
  </div>
</template>
<script>
// 引入全局实例
import ace from "ace-builds";
// 主题风格，引入主题后还需要在 options 中指定主题才会生效
import "ace-builds/src-min-noconflict/theme-monokai";
import "ace-builds/src-min-noconflict/theme-dracula";
import "ace-builds/src-min-noconflict/theme-chrome";
import "ace-builds/src-min-noconflict/theme-dawn";
import "ace-builds/src-min-noconflict/theme-crimson_editor";
// 支持代码格式， 需要引入具体的语法高亮库才会有对应的语法高亮效果
import "ace-builds/src-min-noconflict/mode-javascript";
import "ace-builds/src-min-noconflict/mode-json";
import "ace-builds/src-min-noconflict/mode-css";

import "ace-builds/src-min-noconflict/snippets/json";
import "ace-builds/src-min-noconflict/mode-html";
import "ace-builds/src-min-noconflict/mode-xml";
import "ace-builds/src-min-noconflict/mode-less";
import "ace-builds/src-min-noconflict/mode-gitignore.js";

import "ace-builds/src-min-noconflict/ext-language_tools";
import jsWorkerUrl from "file-loader!ace-builds/src-noconflict/worker-javascript";
import jsonWorkerUrl from "file-loader!ace-builds/src-noconflict/worker-json";
import cssWorkerUrl from "file-loader!ace-builds/src-noconflict/worker-css";
import htmlWorkerUrl from "file-loader!ace-builds/src-noconflict/worker-html";
import xmlWorkerUrl from "file-loader!ace-builds/src-noconflict/worker-xml";
import gitignoreWorkerUrl from "file-loader!ace-builds/src-noconflict/snippets/gitignore";
ace.config.setModuleUrl("ace/mode/javascript_worker", jsWorkerUrl);
ace.config.setModuleUrl("ace/mode/json_worker", jsonWorkerUrl);
ace.config.setModuleUrl("ace/mode/css_worker", cssWorkerUrl);
ace.config.setModuleUrl("ace/mode/html_worker", htmlWorkerUrl);
ace.config.setModuleUrl("ace/mode/xml_worker", xmlWorkerUrl);
ace.config.setModuleUrl("ace/mode/gitignore_worker", gitignoreWorkerUrl);

ace.config.setModuleUrl(
  "ace/snippets/javascript",
  require("file-loader!ace-builds/src-noconflict/snippets/javascript.js")
);
ace.config.setModuleUrl(
  "ace/snippets/css",
  require("file-loader!ace-builds/src-noconflict/snippets/css.js")
);

// import { cloneDeep } from "@/utils/tool";
export default {
  name: "CodeEditor",
  model: {
    event: "change",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: true,
    },
    // 默认语言
    language: {
      type: String,
      default: "xml",
    },
    // 主题，对应主题库 JS 需要提前引入
    theme: {
      type: String,
      default: "chrome",
    },
    // 是否只读
    readonly: {
      type: Boolean,
      default: false,
    },
    // 最大行数
    maxLines: {
      type: Number,
      default: 1000,
    },
    // 是否显示全屏按钮
    withFullscreenBtn: {
      type: Boolean,
      default: false,
    },
    withFooterBtns: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editor: null,
      generateId: "id_" + Math.random().toString(36).substr(2, 4),
      isVisible: false,
      dialogValue: "",
    };
  },
  mounted() {
    // 初始化
    this.initEditor();
  },
  watch: {
    value(val) {
      if (this.editor.getValue() !== val) {
        this.editor.setValue(val);
        this.editor.clearSelection();
      }
    },
    theme(val) {
      this.editor.setTheme(`ace/theme/${val}`);
    },
    language(val) {
      //设置语言
      this.editor.session.setMode(`ace/mode/${val}`);
    },
  },
  methods: {
    // 初始化
    initEditor() {
      // 创建实例
      this.editor = ace.edit(this.$refs[this.generateId], {
        mode: `ace/mode/${this.language}`,
        theme: `ace/theme/${this.theme}`,
        fontSize: 12,
        tabSize: 2,
        value: this.value,
        selectionStyle: "text",
        maxLines: this.maxLines,
        readOnly: this.readonly,
        autoScrollEditorIntoView: true, //启动滚动
        minLines: 35,
        maxLines: 35,
        showPrintMargin: false, //去除编辑器里的竖线
      });
      // 设置属性等，具体需要可根据官方参数自行设置
      this.editor.setOptions({
        enableBasicAutocompletion: true, // 启用基本自动完成
        enableSnippets: true, // 启用代码段
        enableLiveAutocompletion: true, // 启用实时自动完成
        wrap: true, //换行
        setShowPrintMargin: false,
        readOnly: true, // 是否只读
      });
      // 设置值改变监听
      this.editor.on("change", () => {
        this.$emit("change", this.editor.getValue());
      });
    },
    // 实例方法，高亮某一行
    gotoLine(lineNumber) {
      this.editor.gotoLine(lineNumber);
    },
    // 全屏编辑
    fullscreen() {
      this.dialogValue = cloneDeep(this.editor.getValue());
      this.isVisible = true;
    },
    closeEditCode() {
      this.editor.setValue(this.dialogValue);
      this.editor.clearSelection();
    },
    // resize编辑器
    resize() {
      this.editor.resize(true);
    },
    destroy() {
      if (this.editor) {
        this.editor.destroy();
        this.editor = null;
      }
    },
  },
  beforeDestroy() {
    this.destroy();
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  position: relative;
  .code-editor {
    min-height: 200px;
    height: 600px;

    // border: 1px solid #282f3a;
    // background-color: #0e1013;
  }
  .icon-fullscreen {
    position: absolute;
    // color: #fff;
    right: 10px;
    font-size: 16px;
    z-index: 9999;
    cursor: pointer;
  }
}
::v-deep.code-dialog {
  &::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background-image: linear-gradient(270deg, #00deff, #2483ff 74%);
  }
  display: flex;
  flex-direction: column;
  background-color: #303640;
  .el-dialog__header {
    border: none;
    .el-dialog__title {
      color: #ccc;
    }
  }
  .el-dialog__body {
    flex: 1 1 0;
    padding-top: 10px;
  }
}
</style>

