<template>
  <el-dialog :title="isEdit ? '编辑' : '新增'" width="750px" v-if="show" :visible="show" @close="close" destroy-on-close :close-on-click-modal="false">

    <el-form ref="form" :model="form" label-width="100px" :rules="rules" class="h100">
      <div class="h100 flex page-wrapper">
        <common-layout style="width:750px">
          <el-form-item label="开发语言：" prop="language">
            <el-radio v-model="form.language" label="Java">Java</el-radio>
          </el-form-item>
          <el-form-item label="编译版本：" prop="version">
            <el-radio v-model="form.version" label="8.0">8.0</el-radio>
          </el-form-item>
          <el-form-item label="项目类型：">

            <el-radio-group v-model="form.project">
              <el-radio label="maven">maven</el-radio>
              <el-radio label="gradle">gradle</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否微服务：">
            <el-radio-group v-model="form.microService">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否容器部署：">
            <el-radio-group v-model="form.dockerDeploy">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="标签：" prop="labelId">
            <el-select v-model="form.labelId" clearable class="inputWidth mr20" @change="labelChange">
              <el-option v-for="(item) in labelList" :key="item.id" :value="item.id" :label="item.label"></el-option>
            </el-select>
            <el-button type="primary" @click="addLabel()">新增</el-button>
          </el-form-item>
          <el-form-item label="Name：" prop="name">
            <el-input v-model="form.name" class="inputWidth" clearable></el-input>
          </el-form-item>
          <el-form-item label="Group：" prop="groupId">
            <el-input v-model="form.groupId" class="inputWidth" clearable></el-input>
          </el-form-item>
          <el-form-item label="Artifact：" prop="artifactId">
            <el-input v-model="form.artifactId" class="inputWidth" clearable></el-input>
          </el-form-item>
          <el-form-item label="Description：">
            <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="form.description" class="inputWidth" clearable>
            </el-input>
          </el-form-item>
        </common-layout>
      </div>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <!-- <el-button type="primary" @click="handlePreview()">预览代码</el-button> -->
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </div>
    <el-drawer title="标签管理" :visible.sync="lableDrawer" :append-to-body="true" size="85%" direction="rtl" @close="getLabel()">
      <lableMgt></lableMgt>
    </el-drawer>
    <!-- <preview-modal :detail="detail" :previewDrawer="previewDrawer" @closePreview="previewDrawer = false"></preview-modal> -->
  </el-dialog>
</template>
<script>
import Api from "@/api/devops/framework";
import commonApi from "@/api/devops/common";
import lableMgt from "../../common/labelMgt.vue";
import selectTable from "../../common/selectTable.vue";
export default {
  components: { lableMgt, selectTable },
  props: {
    show: {
      default: true,
      type: Boolean,
    },
    detail: {
      type: Object,
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    show(val) {
      val ? this.getLabel() : false;
    },
    detail(val) {
      this.isEdit = !_.isEmpty(val);
      this.form = this.isEdit
        ? val
        : {
            language: "Java",
            microService: 0,
            dockerDeploy: 0,
            version: "8.0",
            project: "maven",
            dependencyVersions: [],
          };
    },
  },
  data() {
    return {
      form: {},

      lableDrawer: false,
      previewDrawer: false,
      labelList: [],

      rules: {
        name: [{ required: true, message: "请输入项目名称", trigger: "blur" }],
        labelId: [{ required: true, message: "请选择标签", trigger: "blur" }],
        version: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
        ],
        groupId: [
          { required: true, message: "请输入公司或组织域名", trigger: "blur" },
        ],
        artifactId: [
          { required: true, message: "请输入实际项目名称", trigger: "blur" },
        ],
        language: [
          { required: true, message: "请输入编程语言", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // if (this.form.dependencyVersions.length == 0) {
          //   this.$msg.error("依赖不能为空");
          // } else {
          this.fetch();
          // }
        } else {
          return false;
        }
      });
    },
    async fetch() {
      let params = this.form;
      const fn = this.form.id ? Api.updateEnd : Api.addEnd;
      let res = await fn(params);
      this.$msg.success("操作成功");
      this.close();
      this.$emit("getData");
    },
    getRelyData(data) {
      this.form.dependencyVersions = data;
    },
    close() {
      this.$emit("close");
    },
    async getLabel() {
      let res = await commonApi.getLabel();
      if (res.data) {
        this.labelList = res.data;
      }
    },
    addLabel() {
      this.lableDrawer = true;
    },
    labelChange(value) {
      this.labelList.forEach((item) => {
        if (item.id === value) {
          this.form.label = item.label;
        }
      });
    },
    handlePreview() {
      this.previewDrawer = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.inputWidth {
  width: 60%;
}
</style>