<template>
  <div class="h100">
    <div v-if="showAppList" class="h100 flex page-wrapper">
      <common-layout class="flex-1 flex-column h100">
        <template #header>
          <el-form inline>
            <el-form-item>
              <el-button type="primary" @click="handleEvent('add')">新增</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template #default>
          <el-table ref="table" :data="tableData" border highlight-current-row height="100%">
            <el-table-column label="序号" type="index" width="60" align="center"></el-table-column>
            <el-table-column v-for="item in tableHeader" :key="item.code" :prop="item.code" :label="item.name" align="center" />
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="handlePreview(scope.row)">
                  预览代码
                </el-button>
                <el-button type="text" @click="handleEvent('edit', scope.row)">编辑</el-button>
                <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template #footer>
          <el-pagination class="mt10" :page-size="page.size" :current-page="page.current" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"></el-pagination>
        </template>
      </common-layout>
      <w-form-box :operType="opertype" :curRow="curRow" :data="data" @getData="getData" style="width: 800px" class="ml10 pl10 border-left-line h100 overflow-auto" />

      <preview-modal :detail="previewRowData" :previewDrawer="previewDrawer" @closePreview="previewDrawer = false"></preview-modal>
    </div>
  </div>
</template>

<script>
import WFormBox from "./formBox.vue";
import previewModal from "./previewModal";
import Api from "@/api/devops/framework";

export default {
  components: { WFormBox, previewModal },
  props: {
    data: {
      type: Object,
      default: "",
    },
    editionDrawer: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    data(val) {
      if (val) {
        this.getData();
      }
    },
    editionDrawer(val) {
      if (!val) {
        this.curRow = {};
      }
    },
  },
  data() {
    return {
      showAppList: true,
      tableData: [],
      tableHeader: [
        {
          code: "backFrameworkVersion",
          name: "版本",
        },
        { code: "backFrameworkVersionRemark", name: "备注" },
      ],
      opertype: "add",
      curRow: {},
      formBox: false,
      page: {
        current: 1,
        size: 10,
      },
      total: 0,
      previewRowData: {},
      previewDrawer: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let { id } = this.data;
      let { current, size } = this.page;
      let params = {
        backFrameworkId: id,
        current,
        size,
      };
      let res = await Api.getVersionPage(params);
      this.tableData = res.data.records;
    },
    handleEvent(type, row) {
      switch (type) {
        case "add":
          this.opertype = type;
          this.curRow = {};
          break;
        case "see":
          this.opertype = type;
          this.curRow = { ...row };
          break;
        case "edit":
          this.opertype = type;
          this.curRow = { ...row };

          break;
        case "dev":
          this.$router.push({ path: "/standerDev/appVersion" });
          break;
      }
    },
    handleDelete(data) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await Api.delVersion(data.id);
        this.$msg.success("操作成功");
        this.getData();
      });
    },

    handleQuery() {
      this.page.current = 1;
      this.getData();
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.page.current = 1;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page.current = val;
      this.getData();
    },

    handlePreview(data) {
      this.previewRowData = _.cloneDeep(data);
      this.previewDrawer = true;
    },
  },
};
</script>
<style lang="scss" scoped></style>
