<template>
  <el-dialog title="预览代码" width="1200px" :visible="previewDrawer" @close="close" :append-to-body="true">
    <div class="h100 flex ">
      <common-layout style="width:250px">
        <el-tree :data="treeData" :props="defaultProps" @node-click="handleNodeClick" @node-collapse="handleNodeCollapse" :render-content="renderContent"></el-tree>
      </common-layout>
      <div style="width:100%">
        <div class="mb10">
          <el-switch style="display: block" v-model="themeValue" active-text="深色主题" @change="setTheme" />
        </div>
        <editor ref="_firstRefs" class=" h-100" v-model="testJson" value="hell" readonly :language="language" :theme="theme" :show="show"></editor>
      </div>
    </div>
    <div slot="footer">
      <!-- <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="onSubmit">确定</el-button> -->
    </div>
  </el-dialog>
</template>
<script>
import Api from "@/api/devops/framework";
import Editor from "../../common/aceEditor.vue";
export default {
  components: { Editor },
  props: {
    previewDrawer: {
      default: true,
      type: Boolean,
    },
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  watch: {
    detail(val) {
      this.form = val;
      if (val.id) {
        this.getData();
      }
    },
  },
  data() {
    return {
      show: true,
      themeValue: false,
      theme: "chrome",
      testJson: "",
      defaultProps: {
        children: "childFile",
        label: "fileName",
      },
      treeData: [],
      language: "xml",
    };
  },
  mounted() {},
  methods: {
    onSubmit() {},
    close() {
      this.$emit("closePreview");
      this.testJson = "";
    },

    setTheme(val) {
      if (val == true) {
        this.theme = "monokai";
      } else {
        this.theme = "chrome";
      }
    },
    handleNodeClick(data) {
      if (data.context) {
        this.testJson = data.context;
        this.language = data.fileName.replace(/^[^.]*.([\s\S]*)$/, "$1");
      }
    },
    handleNodeCollapse(data) {
      //节点关闭
      this.testJson = "";
    },
    async getData() {
      let res = await Api.getPreview(this.form.id);
      this.$nextTick(() => {
        this.treeData = [];
        if (res.data) {
          this.treeData[0] = res.data;
        } else {
          this.treeData = [];
        }
      });
    },
    renderContent(h, { node, data, store }) {
      return <span style="font-size:14px;">{node.label}</span>;
    },
  },
};
</script>
<style lang="scss" scoped>
a:hover {
  text-decoration: underline;
}
.inputWidth {
  width: 60%;
}
.text {
  line-height: 30px;
}
.el-tree-node__label {
  font-size: 18px;
}
</style>