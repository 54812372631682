<template>
  <div>
    <div class="common-header">{{ titleData[operType] }}</div>
    <el-form label-width="100px" ref="formData" :model="formData" :rules="rules">
      <el-form-item label="版本:" prop="backFrameworkVersion">
        <el-input v-model="formData.backFrameworkVersion" :disabled="operType === 'edit'" clearable></el-input>
      </el-form-item>
      <el-form-item label="备注:" prop="backFrameworkVersionRemark">
        <el-input class="inputWidth" type="textarea" :rows="2" placeholder="请输入" v-model="formData.backFrameworkVersionRemark">
        </el-input>
      </el-form-item>
      <el-form-item label="dependencies:">
        <el-input class="inputWidth" type="textarea" :rows="4" placeholder="请输入" v-model="formData.dependencyManagement">
        </el-input>
        <el-popover placement="right" width="400" trigger="click">
          <div>
            {{code.text1}}<br>
            {{code.text2}}<br>
            {{code.text3}}<br>
            {{code.text4}}<br>
            {{code.text5}}<br>
            {{code.text6}}<br>
            {{code.text7}}<br>
            {{code.text8}}<br>
            {{code.text9}}<br>
            {{code.text10}}<br>
            {{code.text11}}<br>
            {{code.text12}}
          </div>

          <label slot="reference">
            <span class="promptText">代码示例</span>
            <i class="common-upload-icon el-icon-warning-outline avatar-uploader-icon"></i>
          </label>
        </el-popover>
      </el-form-item>
      <template>
        <div class=" pl10">
          <select-table titleData="新增依赖" :isEdit="operType=='edit'" :versionList="formData.dependencyVersions" @getRelyData="getRelyData" />

        </div>
      </template>
      <el-form-item v-if="operType !== 'see'" class="mt10">
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Api from "@/api/devops/framework";
import selectTable from "../../common/selectTable.vue";

import Editor from "../../common/aceEditor.vue";
export default {
  components: { Editor, selectTable },
  props: {
    curRow: {
      default: () => {
        dependencyVersions: [];
      },
      type: Object,
    },
    data: {
      type: Object,
      default: "",
    },
    operType: { type: String, default: "add" },
  },
  watch: {
    curRow: {
      handler(val) {
        this.formData =
          this.operType == "edit"
            ? val
            : {
                dependencyVersions: [],
              };
      },
      immediate: true,
    },
  },
  data() {
    return {
      formData: {},
      dependList: [],
      titleData: {
        add: "新增版本",
        edit: "修改版本",
        see: "查看详情",
      },
      uuid: 0,

      rules: {
        backFrameworkVersion: [
          { required: true, message: "请输入版本号", trigger: "blur" },
        ],
      },
      labelRules: {
        name: [{ required: true, message: "请输入标签名", trigger: "blur" }],
        value: [{ required: true, message: "请输入标签值", trigger: "blur" }],
      },
      show: true,
      theme: "chrome",
      testJson: "",
      language: "xml",
      code: {
        text1: `<dependencyManagement>`,
        text2: `<dependencies>`,
        text3: `<dependency>`,
        text4: `<groupId>org.springframework.boot</groupId>`,
        text5: `<artifactId>spring-boot-starter-jdbc</artifactId>`,
        text6: `</dependency>`,
        text7: `<dependency>`,
        text8: `<groupId>org.springframework.boot</groupId>`,
        text9: `<artifactId>spring-boot-starter-test</artifactId>`,
        text10: `</dependency>`,
        text11: `</dependencies>`,
        text12: `</dependencyManagement>`,
      },
    };
  },
  methods: {
    onSubmit() {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          this.fetch();
        } else {
          return false;
        }
      });
    },

    async fetch() {
      let params = {
        backFrameworkId: this.data.id,
        ...this.formData,
      };
      const fn = this.formData.id ? Api.updateVersion : Api.addVersion;
      let res = await fn(params);
      this.formData.label = "";
      this.$msg.success("操作成功");
      this.$emit("getData");
    },
    addTable() {
      this.uuid = this.uuid + 1;
      this.dependList.push({
        name: "",
        value: "",
        id: this.uuid,
      });
    },
    delTable(index) {
      this.dependList.splice(index, 1);
    },

    getRelyData(data) {
      this.formData.dependencyVersions = data;
    },
  },
};
</script>
<style lang="scss" scoped>
.promptText {
  color: #606266;
  font-size: 12px !important;
  margin-right: 3px;
  cursor: pointer;
}
</style>
